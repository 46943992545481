<template>
  <v-container
    fluid
    class="pt-0"
  >
    <base-material-card
      color="primary"
      icon="mdi-file-settings"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Relatórios Modelos</div>
      </template>
      <v-btn
        v-if="accessReleased('RELATORIOS_MODELOS_ADICIONAR')"
        color="success"
        id="btn-novo-relatorio-modelo"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.replace('edit')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Novo Relatório
      </v-btn>
      <v-text-field
        v-model="search"
        id="relatorios-modelos-historico-search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />
      <v-divider class="mt-10" />
      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum relatório disponível"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-truncate-column"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.nome }}
              </div>
            </template>
            <span>{{ item.nome }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.grupo_relatorio`]="{ item }">
          {{ item.grupo_relatorio.titulo }}
        </template>
        <template v-slot:[`item.qtd_relatorios_filhos`]="{ item }">
          {{ item.qtd_relatorios_filhos }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="accessReleased('RELATORIOS_MODELOS_EDITAR')"
            id="action-edit-rel-m"
            color="gray"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            @click="$router.replace(`edit/${item.id}`)"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more-rel-m"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="canDeleteItem(item)"
                @click="openDialogDelete(item.id, item.nome)"
              >
                <v-list-item-title>
                  <v-icon small> mdi-trash-can-outline </v-icon>
                  Deletar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  (dialogCreationLog = true),
                    (dialogCreationLogData = {
                      username: item.usuario,
                      created: item.created
                    })
                "
              >
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Clique aqui para recarregar os relatórios modelos</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title"
        >Deletar Relatório Modelo #{{ dialogDeleteData.id }}</template
      >
      <template slot="body"
        >Tem certeza que deseja deletar o relatório modelo
        <strong>{{ dialogDeleteData.titulo }}</strong
        >?</template
      >
    </dialog-delete>
  </v-container>
</template>

<script>
import RelatoriosModelosService from '@/services/RelatoriosModelosService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],

  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete')
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    powerupService: RelatoriosModelosService,
    powerupEntityName: 'Relatório Modelo',
    search: '',
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Nome',
        value: 'nome'
      },
      {
        text: 'Grupo',
        value: 'grupo_relatorio'
      },
      {
        text: 'Nº Relatórios Filhos',
        value: 'qtd_relatorios_filhos'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ]
  })
};
</script>
